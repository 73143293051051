export function findImageByDimensions(json, width, height) {
    let closestImage = null;
    let largestImage = null;
    let smallestDiff = Infinity;
    let largestWidth = 0;
    let largestHeight = 0;

    if (json && json.versions) {
        for (let version of json.versions) {
            if (width && height) {
                let widthDiff = version.width - width;
                let heightDiff = version.height - height;

                // Encontramos la versión con la diferencia de altura y ancho más pequeña pero mayor que cero
                if (widthDiff >= 0 && heightDiff >= 0 && widthDiff < smallestDiff && heightDiff < smallestDiff) {
                    smallestDiff = Math.max(widthDiff, heightDiff);
                    closestImage = version;
                }

                // Encuentra la imagen más grande
                if (version.width > largestWidth && version.height > largestHeight) {
                    largestImage = version;
                    largestWidth = version.width;
                    largestHeight = version.height;
                }
            } else if (width) {
                let diff = version.width - width;
                if (diff >= 0 && diff < smallestDiff) {
                    smallestDiff = diff;
                    closestImage = version;
                }
                
                // Encuentra la imagen más grande
                if (version.width > largestWidth) {
                    largestImage = version;
                    largestWidth = version.width;
                }
            } else if (height) {
                let diff = version.height - height;
                if (diff >= 0 && diff < smallestDiff) {
                    smallestDiff = diff;
                    closestImage = version;
                }

                // Encuentra la imagen más grande
                if (version.height > largestHeight) {
                    largestImage = version;
                    largestHeight = version.height;
                }
            }
        }
    }

    return closestImage ? closestImage : (largestImage ? largestImage : {});
}
/**
 * 
 * @param {*} json 
 * @returns {{ srcset: String, src: String }}
 */
export function imageToSrc(json) {
    if(json == undefined) return { srcset: '', src: '' };

    let srcset = '';
    let src = '';
    let largestWidth = 0;

    if (json && json.versions) {
        json.versions.forEach((version, i) => {
            // Creando el srcset
            srcset += version.file.uri + ' ' + version.width + 'w';
            if (i !== json.versions.length - 1) {
                srcset += ', ';
            }

            // Tomamos la versión de mayor tamaño como el src por defecto.
            if (version.width > largestWidth) {
                largestWidth = version.width;
                src = version.file.uri;
            }
        });
    }

    return { srcset, src };
}

/**
 * Returns API Image excluding from versions the edited (if true) or unedited (if false) images
 * @param {Boolean} edited Exclude the edited (if true) or unedited (if false) images
 * @returns An array of API Image Objects
 */
export function filterEditedImages(json, edited = true) {
    if (json && json.versions) {
        json.versions = json.versions.filter(version => version.derivedIsEdited === edited);
        return json;
    }

    return json;
}
