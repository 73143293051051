<template>
    <div class="w-full h-full">
        <router-view />
    </div>
</template>

<script setup>

</script>

<style scoped>

</style>